<template>
  <div class="birth">
    <div class="title">輸入想偷看的生日</div>
    <InputPad @focus="showCalendar=false" @submit="submit" :showCalendar="showCalendar"></InputPad>
    <template v-if="!showCalendar">
      <div class="desc">
        西元年月日
        共
        <span>8</span>
        個數字
      </div>
      <div class="limit">
        <span>&#10056 國曆</span>
        <span>&#10056 台灣時間</span>
        <span>&#10056 中間無空格</span>
      </div>
      <div class="sample">範例：19881231</div>
      <div class="sample time-trans" @click="changeTime">外國時區轉換 ></div>
    </template>
    <calendar
      class="calendar-wrapper"
      v-model="curr"
      v-if="showCalendar"
      :detail="detail"
      :birth="birth"
      @modalEvent="modalEvent"
    />
    <Modal v-if="modal.show" :payload="modal" @close="modal.show=false" />
  </div>
</template>

<script>
import InputPad from "@/components/inputPad";
import Calendar from "@/components/calendar";
import Modal from "@/components/modal";
import ModalEvent from "@/mixins/modal";
import Util from "@/mixins/util";
const dateFns = require("date-fns");

export default {
  name: "Peep",
  components: {
    Calendar,
    InputPad,
    Modal,
  },
  mixins: [ModalEvent, Util],
  data: function () {
    return {
      liffId: "1653437596-0NLOxwJw",
      title: "可以偷看別人一整年",
      curr: new Date(),
      selectedMurphy: {},
      showCalendar: false,
      detail: false,
      birth: "",
    };
  },
  computed: {},
  methods: {
    changeTime() {
      location.href = "https://time.is/zh_tw/compare";
    },
    submit(date) {
      if (date.length < 8) {
        this.modalEvent("birthError");
        return false;
      }
      let year = parseInt(date.slice(0, 4));
      let month = parseInt(date.slice(4, 6));
      let day = parseInt(date.slice(6, 8));
      if (
        year < 1942 ||
        year > 2021 ||
        month < 0 ||
        month > 12 ||
        day < 0 ||
        day > 31
      ) {
        this.modalEvent("birthError");
        return false;
      }

      let checkDate = new Date();
      checkDate.setFullYear(year, month - 1, day);
      if (
        checkDate.getFullYear() != year ||
        checkDate.getMonth() != month - 1 ||
        checkDate.getDate() != day
      ) {
        this.modalEvent("birthError");
        return false;
      }

      // todo
      // date = date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8);
      // console.log(date);
      this.birth = date;
      this.showCalendar = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.birth {
  font-family: "Noto Sans TC", sans-serif !important;
  text-align: center;
  min-height: 100vh;
  background-color: #0e0a32;
  .title {
    color: #00ffff;
    font-size: 24px;
    margin: 0px 0px 10px 0px;
  }
  .desc {
    color: #eed9f3;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 500;
    span {
      font-size: 28px;
    }
  }
  .limit {
    color: #eed9f3;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    span {
      margin: 0px 10px;
    }
  }
  .sample {
    color: #eed9f3;
    font-size: 20px;
    font-weight: 500;
    margin-top: 5px;
  }
  .time-trans {
    color: #f7931e;
    font-size: 20px;
  }
  .calendar-wrapper {
    position: fixed;
    bottom: 0;
  }
}
</style>
